<template>
  <v-row :class="{'fill-height' : isLargeScreen}" justify="center" justify-md="start" no-gutters>
    <v-col cols="10" md="12">
      <v-row :class="{'fill-height' : isLargeScreen}" align="center" justify="center" justify-md="start"
             no-gutters>
        <v-col class="fill-height" cols="12" md="4">
          <kurcc-auth-intro v-if="isLargeScreen" :title="$vuetify.lang.t('$vuetify.pages.auth.register')"/>
        </v-col>
        <v-col cols="12" md="4" offset-md="2">
          <v-row align="start" justify="center" no-gutters>
            <v-col v-if="!isLargeScreen" cols="12">
              <kurcc-back-button :to="{name: 'login'}" class="py-5"/>
            </v-col>
            <v-col class="mt-16" cols="12" md="12" sm="6">
              <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
                <v-form @submit.prevent="handleSubmit(register)">
                  <validation-provider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.pages.auth.email')"
                                       rules="required|email">
                    <v-text-field v-model="email" :error-messages="errors"
                                  :label="$vuetify.lang.t('$vuetify.pages.auth.email')"
                                  autofocus outlined prepend-inner-icon="mdi-email-outline"/>
                  </validation-provider>
                  <validation-provider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.pages.auth.password')"
                                       rules="required">
                    <v-text-field v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                  :error-messages="errors" :label="$vuetify.lang.t('$vuetify.pages.auth.password')"
                                  :type="showPassword ? 'text' : 'password'" outlined
                                  prepend-inner-icon="mdi-lock" @click:append="showPassword = !showPassword"/>
                  </validation-provider>
                  <v-btn :disabled="invalid" :loading="loading" color="secondary" tile type="submit" width="100%">
                    {{ $vuetify.lang.t('$vuetify.pages.auth.register') }}
                  </v-btn>
                  <v-row align="center" class="my-10" justify="center" justify-md="start" no-gutters>
                    <v-col v-if="isLargeScreen" cols="4">
                      <kurcc-back-button :to="{name: 'login'}"/>
                    </v-col>
                    {{ $vuetify.lang.t('$vuetify.pages.auth.haveAccount') }}
                    <v-btn :to="{name: 'login'}" color="secondary" text>
                      {{ $vuetify.lang.t('$vuetify.pages.auth.login') }}
                    </v-btn>
                  </v-row>
                </v-form>
              </validation-observer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'KurccRegisterPage',
  components: {
    ValidationObserver,
    KurccBackButton: () => import('@/modules/app/components/KurccBackButton'),
    KurccAuthIntro: () => import('@/modules/auth/components/KurccAuthIntro')
  },
  data () {
    return {
      email: null,
      password: null,
      showPassword: false,
      loading: false
    }
  },
  computed: {
    isLargeScreen () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  methods: {
    register () {
      this.loading = true
      this.$store.dispatch('register', {
        email: this.email,
        password: this.password
      })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
