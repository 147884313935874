var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:{'fill-height' : _vm.isLargeScreen},attrs:{"justify":"center","justify-md":"start","no-gutters":""}},[_c('v-col',{attrs:{"cols":"10","md":"12"}},[_c('v-row',{class:{'fill-height' : _vm.isLargeScreen},attrs:{"align":"center","justify":"center","justify-md":"start","no-gutters":""}},[_c('v-col',{staticClass:"fill-height",attrs:{"cols":"12","md":"4"}},[(_vm.isLargeScreen)?_c('kurcc-auth-intro',{attrs:{"title":_vm.$vuetify.lang.t('$vuetify.pages.auth.register')}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4","offset-md":"2"}},[_c('v-row',{attrs:{"align":"start","justify":"center","no-gutters":""}},[(!_vm.isLargeScreen)?_c('v-col',{attrs:{"cols":"12"}},[_c('kurcc-back-button',{staticClass:"py-5",attrs:{"to":{name: 'login'}}})],1):_vm._e(),_c('v-col',{staticClass:"mt-16",attrs:{"cols":"12","md":"12","sm":"6"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)}}},[_c('validation-provider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.pages.auth.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.auth.email'),"autofocus":"","outlined":"","prepend-inner-icon":"mdi-email-outline"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.pages.auth.password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.auth.password'),"type":_vm.showPassword ? 'text' : 'password',"outlined":"","prepend-inner-icon":"mdi-lock"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('v-btn',{attrs:{"disabled":invalid,"loading":_vm.loading,"color":"secondary","tile":"","type":"submit","width":"100%"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.pages.auth.register'))+" ")]),_c('v-row',{staticClass:"my-10",attrs:{"align":"center","justify":"center","justify-md":"start","no-gutters":""}},[(_vm.isLargeScreen)?_c('v-col',{attrs:{"cols":"4"}},[_c('kurcc-back-button',{attrs:{"to":{name: 'login'}}})],1):_vm._e(),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.pages.auth.haveAccount'))+" "),_c('v-btn',{attrs:{"to":{name: 'login'},"color":"secondary","text":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.pages.auth.login'))+" ")])],1)],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }